<template>
  <v-menu
    ref="menu1"
    v-model="toggle_menu_date"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    :nudge-left="100"
    max-width="290px"
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-chip
        label
        class="pa-5"
        v-bind="$attrs"
        v-on="on"
      >
        <v-icon left size="22" color="icono">mdi-calendar</v-icon>
        {{selected_date | largeDate}}
        <v-icon small v-text="toggle_menu_date ? 'mdi-menu-up' : 'mdi-menu-down' "></v-icon>
      </v-chip>
    </template>
    <v-date-picker
      v-model="selected_date"
      no-title
      :max="max_date"
      :min="min"
    >
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        @click="toggle_menu_date = false"
      >
        Aceptar
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
import moment from 'moment'
export default {
  name: 'InputFecha',
  props:{
    value: String,
    min: String
  },
  data(){
    return {
      toggle_menu_date: false,
      selected_date: this.value,      
    }
  },
  watch:{
    value(val, oldVal){
      if(val === oldVal) return

      this.selected_date = val;
    },
    selected_date (val, oldVal) {
      if(val === oldVal) return
      
      const date_text = val != '' ? moment(val).format('YYYY-MM-DD') : val
      this.$emit('input', date_text);
    }
  },
  methods:{
    resetDate(){
      this.selected_date = '';
    }
  },
  computed:{
    max_date(){
      return moment().format('YYYY-MM-DD');
    },
  }
}
</script>
